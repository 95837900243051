import { Redirect } from "react-router-dom";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import AssessmentResults from "../../components/assessment-results/assessment-results";

export interface AssessmentHomePageProps {

}

const COMPONENT_CLASS = "p-assessment-home";

const AssessmentHomePage: React.FunctionComponent<AssessmentHomePageProps> = () => {
    const { state } = useAuthState();

    if (state.user && !state.user.isAccountSetup) {
        return <Redirect to="/setup" />;
    }
    if (!state.authenticated) {
        return <Redirect to="/login" />;
    }
    if (state.user && state.user?.leadLinkId && state.user.organizationId === process.env.REACT_APP_LEAD_CAPTURE_ORG_ID) {
        return <Redirect to="/lc-assessment-results" />
    }
    if (state.user && state.user?.subscriptionLinkId) {
        return <Redirect to="/wellness-results" />
    }

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <AssessmentResults secondScreenButtonText="Learn More" showYourWellnessProgramSectionWithoutLicense={false} />
        </div>
    );
};

export default AssessmentHomePage;