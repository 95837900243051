import DashboardPage from "./pages/dashboard-page/dashboard-page";
import GroupsPage from "./pages/groups-page/groups-page";
import OrganizationsPage from "./pages/organizations-page/organizations-page";
import ProfilePage from "./pages/profile-page/profile-page";
import ReportViewerPage from "./pages/reports-page/report-viewer-page";
import ReportsPage from "./pages/reports-page/reports-page";
import SettingsPage from "./pages/settings-page/settings-page";
import UserProfilePage from "./pages/user-profile-page/user-profile-page";
import UsersPage from "./pages/users-page/users-page";
import DeepLinksPage from "./pages/deeplinks-page/deeplinks-page";
import LeadLinksPage from "./pages/lead-links-page/lead-links-page";
import { ReactComponent as SuperAdminIcon } from "./assets/icons/symmio-icons/icon_super-admin.svg";
import { ReactComponent as OrgIcon } from "./assets/icons/nav/icon_org-structure.svg";
import { ReactComponent as ReportingIcon } from "./assets/icons/nav/icon_reporting.svg";
import { ReactComponent as SettingsIcon } from "./assets/icons/nav/icon_settings.svg";
import { ReactComponent as ResourcesIcon } from "./assets/icons/nav/icon_resources.svg";
import { ReactComponent as UsersIcon } from "./assets/icons/nav/icon_users.svg";
import { ReactComponent as DashboardIcon } from "./assets/icons/nav/icon_dashboard.svg";
import { ReactComponent as LinksIcon } from "./assets/icons/nav/icon_links.svg";
// import { ReactComponent as DataExportIcon } from "./assets/icons/nav/icon_data-export.svg";
import GroupEditPage from "./pages/groups-page/group-edit-page";
import LinkAddEditPage from "./pages/links/link-add-edit";
import UserRoles from "./models/user-roles";
import { AddOrganizationPage } from "./pages/organizations-page/add-organization-page";
// import ExportPage from "./pages/data-export-page/data-export-page";
import Lifestyle from "./pages/lifestyle/lifestyle";
import Movement from "./pages/movement/movement";
import AccountSetup from "./pages/account-setup/account-setup";
import ExportPage from "./pages/data-export-page/data-export-page";
import ChangePlan from "./components/subscription/change-plan";
import EnterpriseContactUs from "./components/subscription/enterprise-contact-us";
import LeadLinkAddEditPage from "./pages/lead-capture/lead-link-add-edit-page";
import SubscriptionLinksPage from "./pages/subscription-link/subscription-links-page";
import SubscriptionLinkAddEditPage from "./pages/subscription-link/subscription-link-add-edit-page";
import ResourcesPage from "./pages/resources/resources-page";

export const siteMap = [
    {
        name: "Super Admin",
        path: "/organizations",
        component: OrganizationsPage,
        includeInNav: true,
        includeInUserNav: false,
        icon: SuperAdminIcon,
        navOrder: 10,
        onlySuperadmin: true,
    },
    {
        name: "Org Mgmt",
        path: "/organizations",
        component: OrganizationsPage,
        includeInNav: true,
        includeInUserNav: false,
        icon: SuperAdminIcon,
        navOrder: 10,
        // requiredRoles: [UserRoles.ACCOUNT_HOLDER_ID],
        onlySuperadmin: true,
    },
    {
        name: "Add Organization",
        path: "/organization",
        component: AddOrganizationPage,
        includeInNav: false,
        includeInUserNav: false,
        requiredRoles: [UserRoles.ACCOUNT_HOLDER_ID]
    },
    {
        name: "Structure",
        path: "/groups",
        component: GroupsPage,
        includeInNav: true,
        includeInUserNav: false,
        icon: OrgIcon,
        requiredRoles: [UserRoles.ADMIN_ID, UserRoles.GROUP_MANAGER_ID, UserRoles.ACCOUNT_HOLDER_ID],
        navOrder: 30,
    },
    {
        name: "Edit Group",
        path: "/group/:organizationId/:id",
        component: GroupEditPage,
        includeInNav: false,
        includeInUserNav: false,
        requiredRoles: [UserRoles.ADMIN_ID, UserRoles.GROUP_MANAGER_ID, UserRoles.ACCOUNT_HOLDER_ID],
    },
    {
        name: "Add Group",
        path: "/group/:organizationId",
        component: GroupEditPage,
        includeInNav: false,
        includeInUserNav: false,
        requiredRoles: [UserRoles.ADMIN_ID, UserRoles.GROUP_MANAGER_ID, UserRoles.ACCOUNT_HOLDER_ID],
    },
    {
        name: "Upgrade Organization",
        path: "/settings/change-plan/contact-us",
        component: EnterpriseContactUs,
        includeInNav: false,
        includeInUserNav: false,
        requiredRoles: [UserRoles.ADMIN_ID, UserRoles.ACCOUNT_HOLDER_ID],
    },
    {
        name: "Upgrade Organization",
        path: "/settings/upgrade",
        component: ChangePlan,
        includeInNav: false,
        includeInUserNav: false,
        requiredRoles: [UserRoles.ADMIN_ID, UserRoles.ACCOUNT_HOLDER_ID],
    },
    {
        name: "User Profile",
        path: "/users/:id",
        component: UserProfilePage,
        includeInNav: false,
        includeInUserNav: false,
    },
    {
        name: "Users",
        path: "/users",
        component: UsersPage,
        includeInNav: true,
        includeInUserNav: false,
        icon: UsersIcon,
        requiredRoles: [UserRoles.ADMIN_ID, UserRoles.GROUP_MANAGER_ID, UserRoles.ACCOUNT_HOLDER_ID],
        navOrder: 40,
    },
    {
        name: "Report Viewer",
        path: "/reports/:id",
        component: ReportViewerPage,
        includeInNav: false,
        includeInUserNav: false,
    },
    {
        name: "Reports",
        path: "/reports",
        component: ReportsPage,
        includeInNav: true,
        includeInUserNav: false,
        icon: ReportingIcon,
        navOrder: 50,
    },
    {
        name: "Links",
        path: "/invitelinks",
        component: DeepLinksPage,
        includeInNav: true,
        includeInUserNav: false,
        icon: LinksIcon,
        requiredRoles: [UserRoles.ADMIN_ID, UserRoles.ACCOUNT_HOLDER_ID],
        navOrder: 60,
    },
    {
        name: "Lead Capture",
        path: "/lead-capture",
        component: LeadLinksPage,
        includeInNav: false,
        includeInUserNav: false,
        navOrder: 60,
        onlySuperadmin: true,
    },
    {
        name: "Subscription Links",
        path: "/subscription-links",
        component: SubscriptionLinksPage,
        includeInNav: false,
        includeInUserNav: false,
        navOrder: 60,
        onlySuperadmin: true,
    },
    {
        name: "Settings",
        path: "/settings",
        component: SettingsPage,
        includeInNav: true,
        includeInUserNav: false,
        icon: SettingsIcon,
        navOrder: 70,
    },
    {
        name: "Export",
        path: "/data-export",
        component: ExportPage,
        includeInNav: false,
        includeInUserNav: false,
        navOrder: 80,
        onlySuperadmin: true,
    },
    {
        name: "Resources",
        path: "/resources",
        component: ResourcesPage,
        includeInNav: true,
        includeInUserNav: false,
        icon: ResourcesIcon,
        navOrder: 90,
        onlySuperadmin: false,
    },
    {
        name: "Profile",
        path: "/profile",
        component: ProfilePage,
        includeInNav: false,
        includeInUserNav: false,
    },
    {
        name: "Lifestyle",
        path: "/lifestyle",
        component: Lifestyle,
        includeInNav: false,
    },
    {
        name: "Movement",
        path: "/movement",
        component: Movement,
        includeInNav: false,
    },
    {
        name: "Account Setup",
        path: "/setup",
        component: AccountSetup,
        includeInNav: false,
    },
    {
        name: "Edit Deep Link",
        path: "/invitelink/:id",
        component: LinkAddEditPage,
    },
    {
        name: "Add Deep Link",
        path: "/invitelink/",
        component: LinkAddEditPage,
    },
    {
        name: "Edit Lead Capture Link",
        path: "/lead-capture-link/:id",
        component: LeadLinkAddEditPage,
        onlySuperadmin: true,
    },
    {
        name: "Add Lead Capture Link",
        path: "/lead-capture-link",
        component: LeadLinkAddEditPage,
        onlySuperadmin: true,
    },
    {
        name: "Edit Subscription Link",
        path: "/subscription-link/:id",
        component: SubscriptionLinkAddEditPage,
        onlySuperadmin: true,
    },
    {
        name: "Add Subscription Link",
        path: "/subscription-link",
        component: SubscriptionLinkAddEditPage,
        onlySuperadmin: true,
    },
    {
        name: "Dashboard",
        path: "/dashboard",
        component: DashboardPage,
        includeInNav: true,
        includeInUserNav: false,
        icon: DashboardIcon,
        navOrder: 20,
    },
    // This part is only for setting up the default home page in the router
    {
        name: "",
        path: "/",
        component: DashboardPage,
        includeInNav: false,
        includeInUserNav: false,
        icon: null,
        navOrder: 0,
    },
];
