/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import UserService from '../../utilities/services/user-service';
import { User } from '../../models/interfaces/user';
import { createUserWithEmailAndPassword } from "firebase/auth";
import getFirebase from '../../utilities/firebase';
import { useHistory, useParams } from 'react-router-dom';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import { Modal } from '../../components/modal/modal';
import BaseCardLayout from '../../components/base-card-layout/base-card-layout';
import BaseCardLayoutActions from '../../components/base-card-layout/base-card-layout-actions';
import { MskScore } from '../../models/interfaces/scores/msk-score';
import DeeplinkService from '../../utilities/services/deeplink-service';
import LifestyleAssessmentService from '../../utilities/services/lifestyle-assessment-service/lifestyle-assessment-service';
import { LifestyleAssessment } from '../../models/interfaces/scores/lifestyle-assessment';
import LifestyleScoreService from '../../utilities/services/lifestyle-score-service/lifestyle-score-service';
import { LifestyleScore } from '../../models/interfaces/scores/lifestyle-score';
import MovementScoreService from '../../utilities/services/movement-score-service/movement-score-service';
import { MovementScore } from '../../models/interfaces/scores/movement-score';
import MskScoreServiceAssessments from '../../utilities/services/msk-score-service-assessments/msk-score-service-assessments';
import FirebaseStorage from '../../utilities/firebase-storage';
import { httpsCallable } from 'firebase/functions';
import UserUtilAssessments from '../../utilities/user-util-assessments';
import LoaderAssessments from '../../components/loader-assessments/loader-assessments';
import { SymmioAccessType } from '../../models/enumerations/symmio-access-type';
import { DateTime } from 'luxon';
import { Deeplink } from '../../models/interfaces/deeplink';
import { DeeplinkStatus } from '../../models/enumerations/deeplink-status';
import OrganizationService from '../../utilities/services/organization-service';
import LeadLinksService from '../../utilities/services/lead-links-service';
import { LeadLink } from '../../models/interfaces/lead-link';
import { ReactComponent as CircleIcon } from "../../assets/icons/outline/check-circle-icon.svg";
import { ReactComponent as CheckedIcon } from "../../assets/icons/outline/filled-check-circle-icon.svg";
import { TextTypeInput } from '../../components/forms';
import { EmailTypeInput } from '../../components/forms/email-type-input';
import { CheckboxTypeInput } from '../../components/forms/checkbox-type-input';
import { SubscriptionLink } from '../../models/interfaces/subscription-link';
import SubscriptionLinkService from '../../utilities/services/subscription-link-service';

const COMPONENT_CLASS = "p-link-create-account";
const LEAD_CAPTURE_LINK_TYPE = "leadCapture";
const SUBSCRIPTION_LINK_TYPE = "subscriptionLink";
const FORM_COMPONENT_CLASS = "p-link-create-account";

export interface LinkLifestyleScreenProps {
    user: User;
    mskScore: MskScore;
}

interface LinkCreateAccountPageParams {
    id: string;
    type?: string;
}

const LinkCreateAccountPage: React.FC<LinkLifestyleScreenProps> = (props) => {
    const { id, type } = useParams<LinkCreateAccountPageParams>();
    const isLeadCaptureLink = type === LEAD_CAPTURE_LINK_TYPE;
    const isSubscriptionLink = type === SUBSCRIPTION_LINK_TYPE;
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { state, setState } = useAuthState();
    const { auth } = getFirebase();
    const [passwordError, setPasswordError] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [isLoading] = useState(false);
    const [isCreatingAccount, setIsCreatingAccount] = useState(false);
    const [passwordValidation, setPasswordValidation] = useState({ length: false, uppercase: false, lowercase: false, number: false, special: false });
    const [error, setError] = useState("The link is not valid.");
    const [errorTitle, setErrorTitle] = useState("Invalid Link");
    const [modalVisible, setModalVisble] = useState(false);
    const [isCheckedTerms, setIsCheckedTerms] = useState(false);
    const { functions } = getFirebase();
    let history = useHistory();
    let authUser;

    useEffect(() => {
        if (state.authenticated) {
            history.replace("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createUserFromDeeplink = async (email: string) => {
        const callableFunction = httpsCallable(functions, "automatedCreateUserFromDeeplink");

        await callableFunction({
            user: props.user,
            linkId: id,
            email: email,
            isLeadCaptureLink: isLeadCaptureLink,
            isSubscriptionLink: isSubscriptionLink,
        });
        return true;
    }

    const checkDeeplinkValidity = async (deeplink: Deeplink | null) => {
        const nowUtc = DateTime.now().toUTC();
        if (!deeplink) {
            setModalVisble(true);
            setIsCreatingAccount(false);
            throw new Error("Invalid Link");
        }

        const startDate = deeplink.startDate ? DateTime.fromISO(deeplink.startDate) : null;
        const endDate = deeplink.endDate ? DateTime.fromISO(deeplink.endDate) : null;

        const errorFutureHeader = "Opening Soon";
        const errorFutureBody = `The enrollment date is set to start ${startDate?.toLocaleString(DateTime.DATE_FULL)}.\nPlease return at a later date to perform your functional wellness assessment.`;

        const errorExpiredHeader = "Link Expired";
        const errorExpiredBody = "The enrollment date has passed.\nPlease contact your Symmio administrator to request a new link.";

        const errorInactiveHeader = "Link Deactivated";
        const errorInactiveBody = "The enrollment link has been deactivated.\nPlease contact your Symmio administrator to request a new link.";

        const errorLimitHeader = "Invitation Closed";
        const errorLimitBody = "This invitation link has reached its response limit.\nPlease contact your Symmio administrator to request a new invite.";
        // If start date is a future date
        if (startDate && nowUtc <= startDate.toUTC().startOf("day")) {
            setErrorTitle(errorFutureHeader);
            setError(errorFutureBody);
            setModalVisble(true);
            throw new Error(errorFutureHeader);
        }

        // If end date has passed
        else if (endDate && nowUtc >= endDate.toUTC().endOf("day")) {
            setErrorTitle(errorExpiredHeader);
            setError(errorExpiredBody);
            setModalVisble(true);
            throw new Error(errorExpiredHeader);
        }

        // If the deeplink is not active
        else if (deeplink.status !== DeeplinkStatus.Active) {
            setErrorTitle(errorInactiveHeader);
            setError(errorInactiveBody);
            setModalVisble(true);
            throw new Error(errorInactiveHeader);
        }

        // If the link has reached its usage limit
        else if (deeplink.used && deeplink.limit && deeplink.used >= deeplink.limit) {
            setErrorTitle(errorLimitHeader);
            setError(errorLimitBody);
            setModalVisble(true);
            throw new Error(errorLimitHeader);
        }

        else if (deeplink.organizationId) {
            const org = await OrganizationService.get(deeplink.organizationId);

            // If the organization has reached its user limit
            if (org && org.userLimit && org.userCount && org.userCount >= org.userLimit) {
                setErrorTitle(errorLimitHeader);
                setError(errorLimitBody);
                setModalVisble(true);
                throw new Error(errorLimitHeader);
            }

            // If the organization has reached its license limit
            else if (deeplink.linkType === SymmioAccessType.AppLicense && org && org.mySymmioLicense && org.mySymmioLicenseCount && org.mySymmioLicenseCount >= org.mySymmioLicense) {
                setErrorTitle(errorLimitHeader);
                setError(errorLimitBody);
                setModalVisble(true);
                throw new Error(errorLimitHeader);
            }
        }
    }

    const onSubmit = async (data: any) => {
        setIsCreatingAccount(true);
        const { email, password, passwordTwo } = data;
        if (password !== passwordTwo) {
            setPasswordError("Passwords do not match");
            setIsCreatingAccount(false);
            return;
        }
        const existingUser = await UserService.getBy([{ field: "email", operator: "==", value: email }])
        if (existingUser.length > 0) {
            setEmailError("Email already in use!");
            setIsCreatingAccount(false);
            return;
        }

        let deepLink: Deeplink | null = null;
        let leadLink: LeadLink | null = null;
        let subscriptionLink: SubscriptionLink | null = null;
        if (isLeadCaptureLink) {
            leadLink = await LeadLinksService.get(id).catch((err: any) => {
                setError("Something went wrong when retrieving the link");
                setModalVisble(true);
                setIsCreatingAccount(false);
                return null;
            });

            if (!leadLink) {
                setModalVisble(true);
                setIsCreatingAccount(false);
                return;
            }
        }
        else if (isSubscriptionLink) {
            subscriptionLink = await SubscriptionLinkService.get(id).catch((err: any) => {
                setError("Something went wrong when retrieving the link");
                setModalVisble(true);
                setIsCreatingAccount(false);
                return null;
            });

            if (!subscriptionLink) {
                setModalVisble(true);
                setIsCreatingAccount(false);
                return;
            }
        }
        else {
            deepLink = await DeeplinkService.get(id).catch((err: any) => {
                setError("Something went wrong when retrieving the link");
                setModalVisble(true);
                setIsCreatingAccount(false);
                return null;
            });
            if (!deepLink) {
                setModalVisble(true);
                setIsCreatingAccount(false);
                return;
            }

            // The main reasons for validating the Deeplink at the very end are:
            // the link limit, the org user limit, and org license limit.
            // The Lead Capture Link and Subscription Link don't have neither of those, so we only validate the deeplink at this stage.
            try {
                await checkDeeplinkValidity(deepLink);
            } catch {
                setModalVisble(true);
                setIsCreatingAccount(false);
                return
            }
        }
        await createUserFromDeeplink(email);
        let users = await UserService.getBy([{
            field: 'email',
            operator: '==',
            value: email.toLowerCase(),
        }])
        if (!users || users.length === 0) {
            // error handling
            setIsCreatingAccount(false);
            return;
        }
        let user = users[0];
        try {
            authUser = await createUserWithEmailAndPassword(
                auth,
                email.toLowerCase(),
                password
            );
        } catch (error: any) {
            // This doesn't seem to be used anywhere
            // setGlobalError("Signup Creating Account", error);

            switch (error.code) {
                case "auth/user-not-found":
                    setEmailError("Email does not exist!");
                    break;
                case "auth/email-already-in-use":
                    setEmailError("Email already in use!");
                    break;
                case "auth/invalid-email":
                    setEmailError("Invalid Email!");
                    break;
                case "auth/weak-password":
                    setPasswordError(
                        "Password should be at least 6 characters!"
                    );
                    break;
                default:
                    setErrorTitle("Oh no!");
                    setError(
                        "Something went wrong. We are working on fixing the problem. Please try again later."
                    );
                    setModalVisble(true);
                    break;
            }
            setIsCreatingAccount(false);
            // We must delete the user that was created
            await UserService.deleteById(user.id!);
            return;
        }
        if (authUser?.user == null) {
            setPasswordError(`User ${email.toLowerCase()} not found`);
            setIsCreatingAccount(false);
            return;
        }

        // Account has been successfully created, we must update the deeplink
        if (deepLink) {
            if (deepLink!.createdUserEmails) {
                deepLink!.createdUserEmails.push(email.toLowerCase());
            } else {
                deepLink!.createdUserEmails = [];
                deepLink!.createdUserEmails.push(email.toLowerCase());
            }

            deepLink!.used = deepLink!.createdUserEmails.length;

            await DeeplinkService.update(deepLink!).catch((error: any) => {
                setError("Couldn't update the link with the newly created user. Please try again.");
                setModalVisble(true);
                setIsCreatingAccount(false);
                UserService.deleteById(user.id!);
                return;
            });
        }
        else if (leadLink) {
            if (leadLink.createdUserEmails) {
                leadLink.createdUserEmails.push(email);
            } else {
                leadLink.createdUserEmails = [];
                leadLink.createdUserEmails.push(email);
            }

            if (leadLink.leadsTotalCount !== undefined) {
                leadLink.leadsTotalCount += 1;
            }
            else {
                leadLink.leadsTotalCount = 1;
            }

            const currentYear = (new Date()).getUTCFullYear();
            const currentMonthIndex = (new Date()).getUTCMonth();

            if (leadLink.performance && leadLink.performance[currentYear][currentMonthIndex]) {
                leadLink.performance[currentYear][currentMonthIndex].leadsCount += 1;
            }
            else {
                leadLink.performance = {
                    [currentYear]: {
                        [currentMonthIndex]: {
                            clicksCount: 0,
                            leadsCount: 1,
                            starterCount: 0,
                            businessCount: 0,
                            enterpriseCount: 0,
                        }
                    }
                }
            }

            await LeadLinksService.update(leadLink).catch((error: any) => {
                setError("Couldn't update the link with the newly created user. Please try again.");
                setModalVisble(true);
                setIsCreatingAccount(false);
                UserService.deleteById(user.id!);
                return;
            });
        }
        else if (subscriptionLink) {
            if (subscriptionLink!.createdUserEmails) {
                subscriptionLink!.createdUserEmails.push(email);
            } else {
                subscriptionLink!.createdUserEmails = [];
                subscriptionLink!.createdUserEmails.push(email);
            }

            subscriptionLink!.used = subscriptionLink!.createdUserEmails.length;

            await SubscriptionLinkService.update(subscriptionLink!).catch((error: any) => {
                setError("Couldn't update the link with the newly created user. Please try again.");
                setModalVisble(true);
                setIsCreatingAccount(false);
                UserService.deleteById(user.id!);
                return;
            });
        }
        // Save each lifestyle assessment individually, this is each object in lifestyle score (you need the new userId) (lifestyleAssessments)
        LifestyleAssessmentService.addOrUpdateRecentByUserId({
            groupId: props.mskScore.lifestyleScore?.behavioralScore?.groupId,
            groupName: props.mskScore.lifestyleScore?.behavioralScore?.groupName,
            percentage: props.mskScore.lifestyleScore?.behavioralScore?.percentage,
            userId: user.id
        } as LifestyleAssessment, user.id || "")
        LifestyleAssessmentService.addOrUpdateRecentByUserId({
            groupId: props.mskScore.lifestyleScore?.bodyCompositionScore?.groupId,
            groupName: props.mskScore.lifestyleScore?.bodyCompositionScore?.groupName,
            percentage: props.mskScore.lifestyleScore?.bodyCompositionScore?.percentage,
            userId: user.id
        } as LifestyleAssessment, user.id || "");
        LifestyleAssessmentService.addOrUpdateRecentByUserId({
            groupId: props.mskScore.lifestyleScore?.breathingScore?.groupId,
            groupName: props.mskScore.lifestyleScore?.breathingScore?.groupName,
            percentage: props.mskScore.lifestyleScore?.breathingScore?.percentage,
            userId: user.id
        } as LifestyleAssessment, user.id || "");
        LifestyleAssessmentService.addOrUpdateRecentByUserId({
            groupId: props.mskScore.lifestyleScore?.injuryHistoryScore?.groupId,
            groupName: props.mskScore.lifestyleScore?.injuryHistoryScore?.groupName,
            percentage: props.mskScore.lifestyleScore?.injuryHistoryScore?.percentage,
            userId: user.id
        } as LifestyleAssessment, user.id || "");
        LifestyleAssessmentService.addOrUpdateRecentByUserId({
            groupId: props.mskScore.lifestyleScore?.nutritionScore?.groupId,
            groupName: props.mskScore.lifestyleScore?.nutritionScore?.groupName,
            percentage: props.mskScore.lifestyleScore?.nutritionScore?.percentage,
            userId: user.id
        } as LifestyleAssessment, user.id || "");
        LifestyleAssessmentService.addOrUpdateRecentByUserId({
            groupId: props.mskScore.lifestyleScore?.physicalActivityScore?.groupId,
            groupName: props.mskScore.lifestyleScore?.physicalActivityScore?.groupName,
            percentage: props.mskScore.lifestyleScore?.physicalActivityScore?.percentage,
            userId: user.id
        } as LifestyleAssessment, user.id || "");
        LifestyleAssessmentService.addOrUpdateRecentByUserId({
            groupId: props.mskScore.lifestyleScore?.sleepScore?.groupId,
            groupName: props.mskScore.lifestyleScore?.sleepScore?.groupName,
            percentage: props.mskScore.lifestyleScore?.sleepScore?.percentage,
            userId: user.id
        } as LifestyleAssessment, user.id || "");
        // Save the overall lifestyle score (lifestyleScores) (just the percentage really)
        LifestyleScoreService.addOrUpdateRecentByUserId({ percentage: props.mskScore.lifestyleScore?.percentage, userId: user.id } as LifestyleScore, user.id || "")
        // Save the movementScore object
        MovementScoreService.addOrUpdateRecentById({
            toeTouchScore: props.mskScore.movementScore?.toeTouchScore,
            shoulderMobilityScore: props.mskScore.movementScore?.shoulderMobilityScore,
            rotationScore: props.mskScore.movementScore?.rotationScore,
            squatScore: props.mskScore.movementScore?.squatScore,
            balanceScore: props.mskScore.movementScore?.balanceScore,
            focusArea: props.mskScore.movementScore?.focusArea,
            hasShoulderClearingPain: props.mskScore.movementScore?.hasShoulderClearingPain,
            hasSpineClearingPain: props.mskScore.movementScore?.hasSpineClearingPain,
            resultDescription: props.mskScore.movementScore?.resultDescription,
            resultLevel: props.mskScore.movementScore?.resultLevel,
            score: props.mskScore.movementScore?.score,
            userId: user.id,
        } as MovementScore, user.id || "")
        // Save the MskScore
        MskScoreServiceAssessments.addOrUpdateRecentById(props.mskScore, user.id || "");
        // Update user if pain exists
        try {
            await UserUtilAssessments.handleNewMskScore(user.id!, {
                mskScore: props.mskScore,
                wasMovementRetake: true,
            });
        } catch (err) {
            console.log(err);
            setIsCreatingAccount(false);
        }
        // For some reason after creating user it's supposed to log them in
        // Instead its not logging them in but creating an auth session that's 
        // in a weird state. They can't access any pages and are stuck in login screen
        // but can't login because there is already an auth session active.
        // auth.signOut();
        // setState((state) => ({ ...state, ...{ authenticated: false, user: null } }));

        // This signs them in again similar to the createUserWithEmailAndPassword, they aren't quite logged in,
        // but auth thinks they are. They then can't login until refreshing the page.
        // signInWithEmailAndPassword(auth, data.email, data.password)
        //     .catch((error: any) => {
        //         handleError(error);
        //         setModalVisble(true);
        //     });
        setIsCreatingAccount(false);

        if (isLeadCaptureLink) {
            history.push("/lc-assessment-results")
        }
        else if (isSubscriptionLink) {
            history.push("/wellness-results")
        }
        else {
            history.push("/wellness-assessment")
        }
    }


    const onModalDismiss = () => {
        setModalVisble(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleError = (error: any) => {
        if (error?.code == null) {
            setError("Something went wrong. We are working on fixing the problem. Please try again later.");
        }

        switch (error.code) {
            case "auth/user-not-found":
                setError("Incorrect Username or Password.");
                break;
            case "auth/wrong-password":
                setError("Incorrect Username or Password.");
                break;
            case "auth/too-many-requests":
                setError("Too many log-ins have been attempted on this account. Apologies for any inconvenience.");
                break;
            case "auth/internal-error":
                setError("An error occurred. Please try again later.");
                break;
            default:
                setError("Something went wrong. We are working on fixing the problem. Please try again later.");
                break;
        }
    }

    function handlePasswordValidation(value: any): void {
        setPasswordValidation({
            length: value.length >= 8,
            uppercase: /[A-Z]/.test(value),
            lowercase: /[a-z]/.test(value),
            number: /\d/.test(value),
            special: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(value)
        });
    }

    return (
        <BaseCardLayout>
            <div className="flex justify-center" style={{ marginTop: "2.3%" }}>
                <LoaderAssessments isLoading={isCreatingAccount} loaderText='Analyzing Your Results. Please Wait...' />
                <div className={`${COMPONENT_CLASS}`}>
                    <Modal
                        onClose={onModalDismiss}
                        isOpen={modalVisible}
                        isLoading={isLoading}
                        title={errorTitle}
                        defaultCenterModal={true}
                        submitButtonText="Try Again"
                        onSubmit={() => onModalDismiss()}
                    >
                        <div>
                            <p>{error}</p>
                            <br />
                        </div>
                    </Modal>
                    <div className={`${COMPONENT_CLASS}__container`}>
                        <div className={`${COMPONENT_CLASS}__image hide-on-mobile`}>
                            <img
                                alt="Thank you"
                                src={new FirebaseStorage().getMediaUrl(
                                    "public/complete/movement-complete@2x.png"
                                )}
                            />
                        </div>
                        <div className={`${COMPONENT_CLASS}__info`}>
                            <div className={`${COMPONENT_CLASS}__header-container`}>
                                <h3>Thank you</h3>
                                <p>You've completed your Health & Wellness Assessment</p>
                            </div>
                            <h4 className="-light">Create your account to view your results:</h4>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className={`${COMPONENT_CLASS}__content`}>
                                    <div className={`${COMPONENT_CLASS}__field`}>
                                        {errors.email && (
                                            <div
                                                role="alert"
                                                className={`${COMPONENT_CLASS} -error`}>
                                                Email is required
                                            </div>
                                        )}
                                        {EmailError && (
                                            <div
                                                role="alert"
                                                className={`${COMPONENT_CLASS} -error`}>
                                                {EmailError}
                                            </div>
                                        )}
                                        <EmailTypeInput
                                            id='email'
                                            label='Email'
                                            hideLabel={true}
                                            inputClassName={`c-input__input ${errors.email &&
                                                `${COMPONENT_CLASS} -showerror`
                                                }`}
                                            registerHook={register}
                                            registerOptions={{
                                                required: true,
                                            }} />
                                    </div>
                                    <div className={`${COMPONENT_CLASS}__field`}>
                                        {errors.password && (
                                            <div
                                                role="alert"
                                                className={`${COMPONENT_CLASS} -error`}>
                                                Password is required
                                            </div>
                                        )}
                                        {passwordError && (
                                            <div
                                                role="alert"
                                                className={`${COMPONENT_CLASS} -error`}>
                                                {passwordError}
                                            </div>
                                        )}
                                        <TextTypeInput
                                            id='password'
                                            type='password'
                                            label='Password'
                                            hideLabel={true}
                                            inputClassName={`c-input__input ${errors.password &&
                                                `${COMPONENT_CLASS} -showerror`
                                                }`}
                                            registerHook={register}
                                            registerOptions={{
                                                required: true,
                                                onChange: (e) => handlePasswordValidation(e.target.value),
                                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\-]{8,}$/
                                            }}
                                            errorState={errors.password} />
                                    </div>
                                    <div className={`${COMPONENT_CLASS}__field`}>
                                        {errors.passwordTwo && (
                                            <div
                                                role="alert"
                                                className={`${COMPONENT_CLASS} -error`}>
                                                Password is required
                                            </div>
                                        )}
                                        {passwordError && (
                                            <div
                                                role="alert"
                                                className={`${COMPONENT_CLASS} -error`}>
                                                {passwordError}
                                            </div>
                                        )}
                                        <TextTypeInput
                                            id='passwordTwo'
                                            type='password'
                                            label='Confirm Password'
                                            hideLabel={true}
                                            inputClassName={`c-input__input ${errors.passwordTwo &&
                                                `${COMPONENT_CLASS} -showerror`
                                                }`}
                                            registerHook={register}
                                            registerOptions={{
                                                required: true,
                                            }}
                                            errorState={errors.passwordTwo} />
                                    </div>
                                    <div style={{ paddingLeft: '1.5em' }}>
                                        Your password must have at least:
                                        <div className={`${FORM_COMPONENT_CLASS}__password_container`}>
                                            <div style={{ width: '50%' }}>
                                                <div className={`${FORM_COMPONENT_CLASS}_item`}>
                                                    {passwordValidation.length ? <div><CheckedIcon style={{ marginRight: '1.5em' }} /></div> : <div><CircleIcon style={{ marginRight: '1.5em' }} /></div>}
                                                    8 characters
                                                </div>
                                                <div className={`${FORM_COMPONENT_CLASS}_item`}>
                                                    {passwordValidation.lowercase ? <div><CheckedIcon style={{ marginRight: '1.5em' }} /></div> : <div><CircleIcon style={{ marginRight: '1.5em' }} /></div>}
                                                    1 lowercase letter </div>
                                                <div className={`${FORM_COMPONENT_CLASS}_item`}>
                                                    {passwordValidation.uppercase ? <div><CheckedIcon style={{ marginRight: '1.5em' }} /></div> : <div><CircleIcon style={{ marginRight: '1.5em' }} /></div>}
                                                    1 uppercase letter </div>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <div className={`${FORM_COMPONENT_CLASS}_item`}>
                                                    {passwordValidation.number ? <div><CheckedIcon style={{ marginRight: '1.5em' }} /></div> : <div><CircleIcon style={{ marginRight: '1.5em' }} /></div>}
                                                    1 number </div>
                                                <div className={`${FORM_COMPONENT_CLASS}_item`}>
                                                    {passwordValidation.special ? <div><CheckedIcon style={{ marginRight: '1.5em' }} /></div> : <div><CircleIcon style={{ marginRight: '1.5em' }} /></div>}
                                                    1 special character </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${COMPONENT_CLASS}__field`}>
                                        <div className={`${COMPONENT_CLASS}__terms`} style={{ rowGap: "0px" }}>
                                            {errors.termsAndConditions && (
                                                <div
                                                    role="alert"
                                                    className={`${COMPONENT_CLASS} -error`}
                                                    style={{ gridRow: "1 / 2", gridColumn: "1 / 3" }}>
                                                    Please accept Terms and Conditions
                                                </div>
                                            )}
                                            <CheckboxTypeInput
                                                id='termsAndConditions'
                                                registerHook={register}
                                                registerOptions={{
                                                    required: true,
                                                    onChange: (e) => {
                                                        setIsCheckedTerms(e.target.checked);
                                                    }
                                                }}
                                                // label='I agree to terms and conditions'
                                                checked={isCheckedTerms}
                                                errorState={errors.termsAndConditions} />
                                            <div>
                                                I agree to&nbsp;
                                                <a
                                                    className="button"
                                                    type="button"
                                                    // onclick="window.location.href='https://www.functionalmovement.com/terms';"
                                                    href="https://www.functionalmovement.com/terms"
                                                    target="_blank" rel="noreferrer">
                                                    terms and conditions.
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: "5%", width: "100%" }}>
                <BaseCardLayoutActions backButton={false} submitButton={true} onSubmit={handleSubmit(onSubmit)} submitButtonText="Create My Account"></BaseCardLayoutActions>
            </div>
        </BaseCardLayout>
    );
}
export default LinkCreateAccountPage;