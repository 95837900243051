import { Redirect } from "react-router-dom";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { AssessmentsUserMenu } from "../../components/assessments-user-menu/assessments-user-menu";
import SubscriptionLinkCheckoutForm from "../../components/subscription-links/subscription-link-checkout-form";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Loader } from "../../components/loader/loader";
import { useState } from "react";
import BaseCardLayout from "../../components/base-card-layout/base-card-layout";

export interface SubscriptionLinkCheckoutPageProps {

}

const COMPONENT_CLASS = "p-subscription-link-checkout";

const SubscriptionLinkCheckoutPage: React.FunctionComponent<SubscriptionLinkCheckoutPageProps> = () => {
  const { state } = useAuthState();
  const stripePromise = process.env.REACT_APP_STRIPE_PUBLIC_KEY ? loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY) : undefined;
  const [isLoading, setIsLoading] = useState(true);

  let showCongratsMessage = false;
  if (state.user && state.user.subscription && state.user.subscription.subscriptionId) {
    showCongratsMessage = true;
  }

  if (!state.authenticated) {
    return <Redirect to="/login" />;
  }
  if (state.user && !state.user.isAccountSetup) {
    return <Redirect to="/setup" />;
  }
  if (state.user && state.user?.leadLinkId && state.user.organizationId === process.env.REACT_APP_LEAD_CAPTURE_ORG_ID) {
    return <Redirect to="/lc-assessment-results" />
  }
  if (state.user && !state.user.subscriptionLinkId) {
    return <Redirect to="/" />
  }

  return (
    <div className={`${COMPONENT_CLASS}`}>
      <Loader isVisible={isLoading} />
      <div className="user-menu">
        <AssessmentsUserMenu profileImage={false} />
      </div>
      {stripePromise &&
        <Elements stripe={stripePromise} options={{ mode: 'setup', currency: 'usd' }}>
          <BaseCardLayout>
            <div className="lg:p-0 px-8">
              <SubscriptionLinkCheckoutForm isLoading={isLoading} setIsLoading={setIsLoading} showBackButton={true} redirectAfterPayment={false} showCongratsMessage={showCongratsMessage} />
            </div>
          </BaseCardLayout>
        </Elements>
      }
    </div >
  );
};

export default SubscriptionLinkCheckoutPage;