/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { Button } from "../button/button";
import { WarningItem } from "../warning-item/warning-item";
import { ReactComponent as IconVisa } from "../../assets/icons/nav/icon_visa.svg";
import { Modal } from '../modal/modal';
import { Organization } from "../../models/interfaces/organization";
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import OrganizationService from "../../utilities/services/organization-service";
import CancellationDialogBox from "../cancellation-dialog-box/cancellation-dialog-box";
import PortalProgressBar from "../portal-progress-bar/portal-progress-bar";
import { OrganizationSubscription } from "../../models/interfaces/organization-subscription";
import OrganizationSubscriptionService from "../../utilities/services/organization-subscription-service";
import { Loader } from "../loader/loader";
import { useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../subscription/checkout-form";
import { PlanType } from "../../models/enumerations/plan-type";
import SavePaymentMethodForm from "../subscription/save-payment-method-form";
import moment from "moment";
import Stripe from "stripe";
import SubscriptionService from "../../utilities/services/subscription-service";
import UserUtil from "../../utilities/user-util";
import EnterpriseContactUs from "../subscription/enterprise-contact-us";
import { PlanId } from "../../models/enumerations/plan-id";
import { AddOnId } from "../../models/enumerations/add-on-id";
import { enqueueSnackbar } from "notistack";
import UserService from "../../utilities/services/user-service";
import { User } from "../../models/interfaces/user";

interface BillingProps {
  products: any[];
}
const COMPONENT_CLASS = "c-billing";

const Billing: React.FC<BillingProps> = (props: BillingProps) => {
  const {
    products
  } = props;
  const [userCount, setUserCount] = useState(0);
  const [userLimit, setUserLimit] = useState(0);
  const [licenseCount, setLicenseCount] = useState(0);
  const [licenseLimit, setLicenseLimit] = useState(0);
  const [openCancel, setOpenCancel] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isStarter, setIsStarter] = useState<boolean>();
  const [isTrial, setIsTrial] = useState<boolean>();
  const [isSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentSuccess] = useState(false);
  const [isPaymentUpdatedSuccess] = useState(false);
  const [isPaymentUpdateFailed] = useState(false);
  const [paymentFailedModalOpen, setPaymentFailedModalOpen] = useState<boolean>(false);
  const [paymentSuccessModalOpen, setPaymentSuccessModalOpen] = useState<boolean>(false);
  const [paymentUpdatedSuccessModalOpen] = useState<boolean>(false);
  const [isPaymentFail] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const [isUpgrading] = useState(false);
  const [organization, setOrganization] = useState<Organization>();
  const [organizationSubscription, setOrganizationSubscription] = useState<OrganizationSubscription>();
  const [getPayment, setGetPayment] = useState<boolean>(false);
  const [, setPaymentFailed] = useState<boolean>(false);
  const [lastFour, setLastFour] = useState<string>();
  const [cardBrand, setCardBrand] = useState<string>();
  const [, setIsValidating] = useState<boolean>();
  const [cardExpiration, setCardExpiration] = useState<string>();
  const { state } = useAuthState();
  const [stripeCustomerFailedPayment, setStripeCustomerFailedPayment] = useState<boolean>();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);
  const stripe: Stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
  const history = useHistory();
  const [, setPaymentSuccessful] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<any[]>();
  const [paymentUpdateFailed, setPaymentUpdateFailed] = useState<boolean>(false);
  const [paymentUpdateSuccessful, setPaymentUpdateSuccessful] = useState<boolean>(false);
  const [isSubscriptionCancelled, setIsSubscriptionCancelled] = useState<boolean>(false);
  const [openConfirmResubscribe, setOpenConfirmResubscribe] = useState(false);
  const [plan, setPlan] = useState<any>();
  const [contactUsEnterpriseOpen, setContactUsEnterpriseOpen] = useState(false);
  const [accountHolder, setAccountHolder] = useState<User>();
  const isAccountHolder = UserUtil.isAccountHolder(state.user);
  const isSuperAdmin = UserUtil.isSuperAdmin(state.claims);

  // Format the price above to USD using the locale, style, and currency.
  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  // Triggers when on initial, if org plan changes, or if payment was updated.
  // Gets the customer & their subscription. Gets credit card info attached to subscription for the Payment Method UI
  useEffect(() => {
    const getCustomerPaymentInfo = async () => {
      // Get account holder
      const accountHolder = await UserService.get(organization && organization.accountHolderId ? organization.accountHolderId : '');
      if (!accountHolder) {
        return;
      }
      setAccountHolder(accountHolder);
      // NOTE: subscription is tied to account holder, we must only use their email when looking for any stripe information
      await stripe.customers.list({
        limit: 1,
        email: `${accountHolder?.email}`,
        expand: ['data.subscriptions']
      }).then(async (customer: any) => {
        // Check for user
        if (customer && customer.data.length > 0) {
          await stripe.paymentMethods.retrieve(
            `${customer.data[0].invoice_settings.default_payment_method}`
          ).then((payment: any) => {
            setLastFour(payment.card.last4);
            setCardExpiration(`${payment.card.exp_month.length === 1 ? "0" + payment.card.exp_month : payment.card.exp_month}/${payment.card.exp_year}`)
            setCardBrand(capitalizeFirstLetter(payment.card.brand));
          }).catch((error: any) => {
            // TODO: If no default payment method retrieved, change display
            console.log(error);
          });
        }
      })

    }
    const checkForFailedPayments = async () => {
      if (organizationSubscription && organizationSubscription?.stripePlanSubscription) {
        await stripe.subscriptions.retrieve(
          organizationSubscription.stripePlanSubscription
        ).then(async (subscription) => {
          // Use latest_invoice to get the latest invoice and check if payment failed
          if (subscription.latest_invoice) {
            await stripe.invoices.retrieve(subscription.latest_invoice.toString());
            if (subscription.status === "past_due") {
              setStripeCustomerFailedPayment(true);
            }
          }
        })
      }

      if (organizationSubscription && organizationSubscription.stripeAddOnSubscription) {
        await stripe.subscriptions.retrieve(
          organizationSubscription.stripeAddOnSubscription
        ).then(async (subscription) => {
          // Use latest_invoice to get the latest invoice and check if payment failed
          if (subscription.latest_invoice) {
            await stripe.invoices.retrieve(subscription.latest_invoice.toString());
            if (subscription.status === "past_due") {
              setStripeCustomerFailedPayment(true);
            }
          }
        })
      }
    }
    if (organization) {
      getCustomerPaymentInfo();
    }
    checkForFailedPayments();
  }, [isStarter, paymentUpdatedSuccessModalOpen, organizationSubscription, paymentUpdateSuccessful, organization])


  useEffect(() => {
    const getBillingHistory = async () => {

      const customer = await stripe.customers.list({
        limit: 1,
        email: `${accountHolder!.email}`,
      })
      if (customer.data.length > 0) {
        await stripe.paymentIntents.list({
          customer: customer.data[0].id,
          limit: 100,
        });
        // let productIds: any[] = [];

        // Only returns a max of 100 invoices
        // If we want more than 100, we must check if 100 are returned
        // then call again with either starting_after or ending_before
        const invoices = await stripe.invoices.list({
          customer: customer.data[0].id,
          status: 'paid',
          limit: 100,
        });
        const filteredInvoices = invoices.data.filter((invoice) => invoice.amount_due > 0)

        // filteredInvoices.forEach(async (invoice: any) => {
        //   // Get price.
        //   console.log(invoice);
        //   productIds.push(invoice.lines.data[0].price.product);

        //   // invoice.product_name = `${priceDoc.name} ${price.recurring?.interval === 'month' ? 'Monthly' : 'Yearly'}`
        // });
        const test = products.filter((item) => item.product.id === PlanId.Business ||
          item.product.id === AddOnId.License || item.product.id === AddOnId.User)
        let structuredInvoices: any[] = [];
        test.forEach((product: any) => {
          if (filteredInvoices.length > 0) {
            const matchingInvoices = filteredInvoices.filter((invoice) => invoice.lines.data[0].price ? invoice.lines.data[0].price.product === product.product.id : "empty" === product.id)
            matchingInvoices.forEach((matchingInvoice) => {
              if (matchingInvoice) {
                structuredInvoices.push({
                  product_id: product.product.id,
                  product_name: product.product.data.name,
                  effective_at: matchingInvoice?.effective_at,
                  amount_paid: matchingInvoice?.amount_paid,
                  hosted_invoice_url: matchingInvoice?.hosted_invoice_url
                })
              }
            })
          }
        })
        setInvoices(structuredInvoices);
      }


    }
    if (products && accountHolder) {
      getBillingHistory();
    }
  }, [products, accountHolder])
  useEffect(() => {
    if (state.organization?.id == null) {
      return;
    }

    const getOrganization = async () => {
      await OrganizationService.getSnapshot(state.organization.id!, (organization: Organization) => {
        setOrganization(organization);
        setIsLoading(false);
        if (organization.plan === PlanType[PlanType.Starter]) {
          setIsStarter(true);
          setIsTrial(false);
        }
        else if (organization.plan === PlanType[PlanType.Trial]) {
          setIsTrial(true);
          setIsStarter(false);
        }
        else {
          setIsStarter(false);
          setIsTrial(false);
        }
      });
    }
    getOrganization();

  }, [state.organization?.id, openCancel, openConfirmResubscribe]);

  useEffect(() => {
    const load = async () => {
      if (organization?.id == null) {
        return;
      }

      const subscription = await OrganizationSubscriptionService.getByOrganizationId(organization.id);
      if (subscription[0] && subscription[0].organizationId) {
        await setOrganizationSubscription(subscription[0]);

        if (subscription[0].stripePlanSubscription) {
          await stripe.subscriptions.retrieve(subscription[0].stripePlanSubscription)
            .then((sub) => {
              setIsSubscriptionCancelled(sub.cancel_at_period_end);
            });
        }
      }
    }
    load();
  }, [state.organization?.id, organization]);

  useEffect(() => {
    if (organizationSubscription && organizationSubscription.plan) {
      setPlan(organizationSubscription.plan.value);
    }
  }, [organizationSubscription]);

  useEffect(() => {
    const closeModal = async () => {
      if (paymentUpdateSuccessful || paymentUpdateFailed) {
        setOpenEdit(false);
      }
    }
    closeModal();

  }, [paymentUpdateFailed, paymentUpdateSuccessful])



  // Saving this code. This is part of the red alert box that let's a user know a payment failed.
  // In that alert box they can click "Upgrade" and this process adds a new payment method and charges the subscription
  // This code handles the request from the checkoutform. We might want to get rid of param checking and use a state variable.
  // useEffect(() => {
  //   const openModal = async () => {
  //     console.log("inside openModal");
  //     const params = new URLSearchParams(window.location.search);
  //     const redirectStatus = params.get("redirect_status");
  //     const editPaymentOnly = params.get("edit_payment_only")

  //     // TODO: instead of checking params, why not send a state variable. Currently doing this for "failed"
  //     // Because the history.redirect/push wasn't reloading the page and triggering this
  //     // Use effect
  //     if (redirectStatus && redirectStatus === "succeeded") {
  //       console.log("inside if");
  //       // It is just a payment method change
  //       if (editPaymentOnly === "true") {
  //         const setupIntentId = params.get("setup_intent");
  //         // We now need to set this new payment as the "default"
  //         const setupIntent = await stripe.setupIntents.retrieve(
  //           setupIntentId
  //         ).then(async (si: any) => {
  //           console.log(si)
  //           // Update customer to use the new payment method as their default
  //           const updatedCustomer = await stripe.customers.update(
  //             si.customer,
  //             {
  //               invoice_settings: {
  //                 default_payment_method: si.payment_method,
  //               },
  //               expand: ['subscriptions']
  //             }
  //           );
  //           console.log(updatedCustomer);

  //           // if they have a subscription, update the subscription's payment method
  //           if (updatedCustomer.subscriptions.data.length > 0) {
  //             const subscription = await stripe.subscriptions.update(
  //               updatedCustomer.subscriptions.data[0].id,
  //               {
  //                 default_payment_method: updatedCustomer.invoice_settings.default_payment_method
  //               }
  //             );
  //             console.log(subscription)
  //           }
  //         });

  //         setPaymentUpdatedSuccessModalOpen(true);
  //       }
  //       // It is a successful payment + plan upgrade
  //       else {
  //         // Update organization
  //         updateOrg();
  //         // Open Payment Success Modal
  //         setPaymentSuccessModalOpen(true);
  //       }

  //     }
  //     else if (redirectStatus && redirectStatus === "failed") {
  //       if (editPaymentOnly === "true") {
  //         setOpenEdit(false);
  //         setPaymentUpdateFailedModalOpen(true);
  //       }
  //       else {
  //         setUpgradeModalOpen(false);
  //         setGetPayment(false);
  //         setPaymentFailedModalOpen(true);
  //       }
  //     }

  //   }
  //   openModal();

  // }, [paymentFailed, paymentUpdateFailed])

  useEffect(() => {
    if (organization) {
      setUserCount(Number(organization!.userCount));
      setUserLimit(Number(organization!.userLimit));
      setLicenseCount(organization!.mySymmioLicenseCount ? organization.mySymmioLicenseCount : 0); //TODO: Add license count
      setLicenseLimit(Number(organization!.mySymmioLicense));
    }
  }, [organization, state.organization?.id]);

  const calculatePercentage = () => {
    return (userCount / userLimit) * 100;
  };

  // Save this code. This is part of the red alert box for a failed business payment. If payment becomes successful at the
  // end of this billing flow, we update their org back to business
  // NOTE: Talk to Kyle regarding this entire flow. Do we want them to buy business from this page? We agreed upon this earlier
  // but I feel like we should only handle buying the subscription in the upgrade page. And this alert box is to be just that; an alert box.
  // If they click the upgrade button, it takes them to the upgrade page.
  // const updateOrg = async () => {
  //   // Get Organization
  //   if (!state.organization.id) {
  //     // Couldn't get organization id
  //     return;
  //   }
  //   const o = await OrganizationService.get(state.organization.id);
  //   const temp = await OrganizationSubscriptionService.getByOrganizationId(state.organization.id);
  //   const oSub = temp[0];

  //   if (o && oSub) {
  //     // Retrieve customer from stripe by Email
  //     const customer = await stripe.customers.list({ limit: 1, email: `${state.user?.email}` })
  //     // Update the subscription by the subscriptionId. Change the item belonging to the subscription
  //     console.log(customer);
  //     if (customer.data.length === 0 || !customer.data[0].id) {
  //       //TODO: Throw an error.
  //       return;
  //     }
  //     const subscription = await stripe.subscriptions.list({
  //       customer: `${customer.data[0].id}`,
  //     });
  //     console.log(subscription);

  //     // Hardcoded check if the current plan is Business (monthly)
  //     if (subscription.data[0].plan.id === "price_1OSiTTHoJbt1xrgTu04t4i2o") {
  //       console.log("business monthly")
  //       o.plan = PlanType[PlanType.Business];
  //       o.userLimit = 50;
  //       o.mySymmioLicense = 10;
  //       OrganizationService.save(o);
  //       oSub.userLimit = 50;
  //       oSub.price = 75;
  //       oSub.plan!.label = PlanType[PlanType.Business];
  //       oSub.plan!.value = PlanType.Business;
  //       oSub.paymentCycle!.label = PaymentCycle[PaymentCycle.Monthly];
  //       oSub.paymentCycle!.value = PaymentCycle.Monthly;
  //       oSub.mySymmioLicense = 10
  //       OrganizationSubscriptionService.save(oSub);
  //     }
  //     // Hardcoded check if the current plan is Business (yearly)
  //     else if (subscription.data[0].plan.id === "price_1OYvhuHoJbt1xrgT6MYKeCX7") {
  //       console.log("business yearly")
  //       o.plan = PlanType[PlanType.Business];
  //       o.userLimit = 50;
  //       o.mySymmioLicense = 10;
  //       OrganizationService.save(o);
  //       oSub.userLimit = 50;
  //       oSub.price = 75;
  //       oSub.plan!.label = PlanType[PlanType.Business];
  //       oSub.plan!.value = PlanType.Business;
  //       oSub.paymentCycle!.label = PaymentCycle[PaymentCycle.Yearly];
  //       oSub.paymentCycle!.value = PaymentCycle.Yearly;
  //       oSub.mySymmioLicense = 10
  //       OrganizationSubscriptionService.save(oSub);
  //     }
  //     console.log("done changing org");
  //   }

  // }

  const [isWarningVisible, setIsWarningVisible] = useState(true);

  const handleWarningClose = () => {
    setIsWarningVisible(false);
  }


  const getWarningMessage = () => {
    var percentage = calculatePercentage();
    if (isWarningVisible && (percentage >= 75 && percentage <= 89)) {
      return <WarningItem
        textColor="#121622"
        iconColor="#FFC79C"
        backgroundColor="#FFF4E4"
        title="You're almost out of users on your current plan."
        text="Upgrade your Symmio account now to get more users and continue managing health and wellness without missing a beat."
        buttonText="Upgrade"
        onClose={handleWarningClose}
        onButtonClick={() => history.push(`settings/upgrade`)}
        isButtonDisabled={!UserUtil.isAccountHolder(state.user)}
      />
    } else if (isWarningVisible && (percentage > 89 && percentage <= 99)) {
      return <WarningItem
        iconColor="#E7514F"
        backgroundColor="#FFE9E5"
        title="You're close to reaching the user limit on your current plan."
        text="Upgrade your Symmio account now to get more users and continue managing health and wellness without missing a beat."
        buttonText="Upgrade"
        onClose={handleWarningClose}
        onButtonClick={() => history.push(`settings/upgrade`)}
        isButtonDisabled={!UserUtil.isAccountHolder(state.user)}
      />
    } else if (isWarningVisible && (percentage === 100 || percentage > 100)) {
      return <WarningItem
        iconColor="#E7514F"
        backgroundColor="#FFE9E5"
        title="You've reached the user limit on your current plan."
        text="Upgrade your Symmio account now to get more users and continue managing health and wellness without missing a beat."
        buttonText="Upgrade"
        onClose={handleWarningClose}
        onButtonClick={() => history.push(`settings/upgrade`)}
        isButtonDisabled={!UserUtil.isAccountHolder(state.user)}
      />
    }
  }

  // const deeplinks = [{ name: "Symmio Enterprise Yearly", date: "May 3rd, 2023", amount: "$15,000.00" }, { name: "Symmio Business Monthly", date: "December 21st, 2023", amount: "$150.00" }];

  const getRenewDate = () => {
    if (organizationSubscription && organizationSubscription.renewDate) {
      const date = new Date(organizationSubscription.renewDate);

      return date.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      });;
    }
  }

  const getCycle = () => {
    if (organizationSubscription) {
      if (organizationSubscription.paymentCycle) {
        switch (organizationSubscription.paymentCycle.label) {
          case 'Monthly':
            return 'Month';
          case 'Yearly':
            return 'Year';
          case 'Daily':
            return 'Day';
          default:
            return organizationSubscription.paymentCycle.label;
        }
      }
    }
  }

  const getPaymentFailedMessage = () => {
    return <WarningItem
      iconColor="#E7514F"
      backgroundColor="#FFE9E5"
      title="Your payment method was declined."
      text="We tried to charge your card but, something went wrong. Please update your payment method. "
      buttonText="Update Payment Method"
      onClose={handleWarningClose}
      onButtonClick={() => { setGetPayment(true); setUpgradeModalOpen(true); }}
    />
  }

  const capitalizeFirstLetter = (test: string) => {
    return test.charAt(0).toUpperCase() + test.slice(1);
  }

  return (
    <div className={COMPONENT_CLASS}>
      <Loader isVisible={isLoading} />
      {getWarningMessage()}
      { // TODO: This will have to change to only when a recurring payment fails
        stripeCustomerFailedPayment &&
        getPaymentFailedMessage()
      }
      {getPayment && stripePromise &&
        <Modal
          isOpen={upgradeModalOpen}
          isLoading={isUpgrading}
          onClose={() => {
            setUpgradeModalOpen(false);
          }}
          title="Complete Upgrade">
          <Elements stripe={stripePromise} options={{ mode: 'subscription', amount: 50, currency: 'usd' }}>
            <CheckoutForm isValidating={setIsValidating} isRetrying={true} paymentSuccessful={setPaymentSuccessful} paymentFailed={setPaymentFailed} returnUrl={`/settings?active=Billing`} products={products} />
          </Elements>
        </Modal>
      }
      <Modal
        isOpen={paymentSuccessModalOpen}
        isLoading={isPaymentSuccess}
        onClose={() => {
          setPaymentSuccessModalOpen(false);
          history.replace(`/settings`)
        }}
        title="Plan Upgraded"
        defaultCenterModal={true}
        submitButtonText="Continue"
        onSubmit={() => {
          setPaymentSuccessModalOpen(false);
          history.replace(`/settings`)
        }}>
        <div>
          <p><b>Thank you for upgrading to the Business Plan!</b></p>
          <p>You will receive an email confirmation shortly.</p>
        </div>
      </Modal>
      <Modal
        isOpen={contactUsEnterpriseOpen}
        isLoading={false}
        onClose={() => {
          setContactUsEnterpriseOpen(false);
        }}
      >
        <EnterpriseContactUs title="Looking to Change your Plan?" isEnterprise={true} />
      </Modal>
      <Modal
        isOpen={paymentUpdateSuccessful}
        isLoading={isPaymentUpdatedSuccess}
        onClose={() => {
          setPaymentUpdateSuccessful(false);
        }}
        defaultCenterModal={true}
        submitButtonText="Continue"
        onSubmit={() => {
          setPaymentUpdateSuccessful(false);
        }}
        title="Payment Method Updated">
        <div className={`${COMPONENT_CLASS}__modal-wrapper`}>
          <div className={`${COMPONENT_CLASS}__modal-content`}>
            <p><b>Your payment method has been updated successfully!</b></p>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={paymentUpdateFailed}
        isLoading={isPaymentUpdateFailed}
        onClose={() => {
          setPaymentUpdateFailed(false);
        }}
        title="Payment Method Update Failed!"
        defaultCenterModal={true}
        submitButtonText="Continue"
        onSubmit={() => {
          setPaymentUpdateFailed(false);
        }}>
        <div className={`${COMPONENT_CLASS}__modal-wrapper`}>
          <div className={`${COMPONENT_CLASS}__modal-content`}>
            <p><b>Your payment method has not been updated!</b></p>
          </div>
        </div>
      </Modal >
      <Modal
        isOpen={paymentFailedModalOpen}
        isLoading={isPaymentFail}
        onClose={() => {
          setPaymentFailedModalOpen(false);
          history.replace(`/settings`)
        }}
        title="Oops. Payment Declined"
        submitButtonText="Continue"
        onSubmit={() => {
          setPaymentFailedModalOpen(false);
          history.replace(`/settings`)
        }}
        defaultCenterModal={true}>
        <div className={`${COMPONENT_CLASS}__modal-wrapper`}>
          <div className={`${COMPONENT_CLASS}__modal-content`}>
            <p><b>Your payment method was declined</b></p>
            <p>We tried to charge your card but, something went wrong.</p>
            <p>Please update your payment method.</p>
          </div>
        </div>
      </Modal>

      <div className={`${COMPONENT_CLASS}__status`}>
        <div className={`${COMPONENT_CLASS}__status__container`}>
          {isSubscriptionCancelled
            ?
            <div className={`${COMPONENT_CLASS}__status__item__wrapper`}>
              <p className="text-left">
                <strong>Your Symmio subscription has been cancelled.</strong>
                <br />
                <br />
                You can continue using your Symmio account until your billing period ends on <strong>{getRenewDate()}</strong>.
                <br />
                <br />
                Once your billing period ends, your Symmio account will be downgraded to the <strong>Starter Plan</strong>.
              </p>
              {(isAccountHolder || isSuperAdmin) &&
                <div className={`${COMPONENT_CLASS}__actions`} style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'flex-end' }}>
                  <Button
                    buttonText="Resubscribe"
                    onClick={() => setOpenConfirmResubscribe(true)} />
                </div>
              }
            </div>
            :
            <div className={`${COMPONENT_CLASS}__status__item__wrapper`}>
              <div className={`${COMPONENT_CLASS}__status__header`}>
                <div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h1 className="plan-card-header">{organizationSubscription?.plan!.label} {organizationSubscription?.paymentCycle ? organizationSubscription.paymentCycle?.label : ''}</h1>
                    {organizationSubscription?.plan?.value !== PlanType.Starter && organizationSubscription?.plan?.value !== PlanType.Trial && (
                      <p className="label-small text-black-gray-50 text-left" style={{ marginBottom: '1rem' }}>Next payment: {getRenewDate()}</p>)}
                    {organizationSubscription?.plan?.value === PlanType.Trial && (
                      <p className="label-small text-black-gray-50 text-left" style={{ marginBottom: '1rem' }}>Trial end date: {getRenewDate()}</p>)}
                  </div>
                  {organizationSubscription?.plan?.value !== PlanType.Starter && organizationSubscription?.paymentCycle && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto', margin: '1rem 0' }}>
                      <p className="data-medium text-left" style={{ marginRight: '0.5rem' }}>${plan === PlanType.Enterprise ? Number(organizationSubscription.price).toLocaleString()
                        : (organizationSubscription.pricePerUser ? Number(organizationSubscription.pricePerUser).toLocaleString() : Number(organizationSubscription?.price).toLocaleString())}</p>
                      <p className="label-small text-black-gray-50 text-left">/&nbsp;{getCycle()}</p>
                    </div>
                  )}
                  {
                    isStarter && (
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto' }}>
                        <p className="data-medium text-left" style={{ fontSize: '35px', fontWeight: 'bold', marginRight: '0.5rem' }}>$0.00</p>
                        <p className="label-small text-black-gray-50 text-left">/ Month</p>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className={`${COMPONENT_CLASS}__status__main`}>
                <PortalProgressBar total={userLimit} currentProgress={userCount} headerText={`${userCount} of ${userLimit} Users`} />
                {
                  organizationSubscription?.mySymmioLicense && (
                    <PortalProgressBar total={licenseLimit} currentProgress={licenseCount} headerText={`${licenseCount} of ${licenseLimit} Wellness Programs Used`} />
                  )
                }
              </div>
              {(isAccountHolder || isSuperAdmin) &&
                <div className={`${COMPONENT_CLASS}__actions`}>
                  {(plan === PlanType.Enterprise) ? (
                    <>
                      <p className={`c-portal-progress-bar__header`}>Contact us to change your plan.</p>
                      <Button
                        buttonText="Contact Us"
                        onClick={() => { setContactUsEnterpriseOpen(true); }} />
                    </>
                  ) : (
                    <>
                      {organizationSubscription?.plan?.value !== PlanType.Starter && organizationSubscription?.plan?.value !== PlanType.Trial &&
                        <Button
                          type="link"
                          disabled={!isAccountHolder}
                          inputClassName={`${COMPONENT_CLASS}__actions__cancel`}
                          onClick={() => { setOpenCancel(true); }}
                          buttonText="Cancel Plan"
                          inputStyle={{ backgroundColor: '#F3F3F4', paddingLeft: '0px' }} />

                      }
                      <Button
                        buttonText="Upgrade Plan"
                        disabled={!isAccountHolder}
                        onClick={() => history.push(`settings/upgrade`)} />
                    </>
                  )}
                </div>
              }
            </div>
          }

          <div className={`${COMPONENT_CLASS}__status__item__wrapper`}>
            <div className={`${COMPONENT_CLASS}__status__header`}>
              <h1 style={{ marginBottom: '1.73rem' }}>Payment Method</h1>
            </div>
            <div className={`${COMPONENT_CLASS}__status__main`}>
              {(plan === PlanType.Enterprise || isTrial) && (
                <>
                  <p className={`c-portal-progress-bar__header`}>Stripe Invoice</p>
                  {plan === PlanType.Enterprise && (
                    <p className={`${COMPONENT_CLASS}__custom-payment-message`}>You are on a custom payment plan.</p>

                  )}
                  {isTrial && (
                    <p className={`${COMPONENT_CLASS}__custom-payment-message`}>You are on a trial plan.</p>

                  )}
                </>
              )
              }
              {plan === PlanType.Starter && (
                <>
                  <p className={`c-portal-progress-bar__header`}>Free Plan</p>
                  <p className={`${COMPONENT_CLASS}__custom-payment-message`}>You are on the starter plan.</p>
                </>
              )
              }
              {plan === PlanType.Business && (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ display: 'flex', width: '6rem', height: '3.5rem', backgroundColor: 'white', borderRadius: '10px', alignItems: 'center', justifyContent: 'center' }}>
                    <IconVisa />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '2rem' }}>
                    <h1 style={{ fontSize: '15px', fontWeight: 'bold' }}>{cardBrand} ending in {lastFour}</h1>
                    <h1 style={{ fontSize: '15px', marginBottom: '0px' }}>Expiry {cardExpiration}</h1>
                  </div>
                </div>
              )}
            </div>
            <div className={`${COMPONENT_CLASS}__actions`}>
              <div></div>
              <Button
                buttonText={`${isStarter ? 'Add' : 'Edit'}`}
                disabled={plan === PlanType.Enterprise || isTrial || isStarter || !isAccountHolder}
                onClick={() => {
                  setOpenEdit(true);
                }} />
            </div>
          </div>
          {!isStarter && !isTrial && invoices && invoices.length > 0 && (
            <>
              <h3 className={`${COMPONENT_CLASS}__invoice-information-header`}>Billing History</h3>
              <div className={`${COMPONENT_CLASS}__invoice-container`}>
                {invoices.map((invoice: any, index: number) => {
                  return (
                    <div className={`${COMPONENT_CLASS}__invoice`} key={`billing_history_${index}`}>
                      <div className={`${COMPONENT_CLASS}__invoice-information`}>
                        <div className={`${COMPONENT_CLASS}__invoice-information-name`}>{invoice.product_name}</div>
                        <div className={`${COMPONENT_CLASS}__invoice-information-date`}>{`${moment(new Date(invoice.effective_at * 1000)).format('MMMM D, Y')}`}</div>
                        <div className={`${COMPONENT_CLASS}__invoice-information-price`}>{USDollar.format((invoice.amount_paid / 100))}</div>
                      </div>

                      <div className={`${COMPONENT_CLASS}__invoice-button-container`}>
                        <Button
                          buttonText="View Invoice"
                          buttonStyle="secondary"
                          onClick={(event) => {
                            event.stopPropagation();
                            window.open(invoice.hosted_invoice_url, "_blank")
                          }}
                        />
                      </div>
                    </div>
                  )

                })}
              </div>
            </>
          )
          }


          <CancellationDialogBox
            isOpen={openCancel}
            isLoading={isSaving}
            onClose={setOpenCancel}
            title="Why are you cancelling?"
          />
          <Modal
            isOpen={openEdit}
            isLoading={isSaving}
            onClose={() => {
              setOpenEdit(false);
            }}
          >
            <Elements stripe={stripePromise} options={{ mode: 'setup', currency: 'usd' }}>
              <SavePaymentMethodForm setOpenEdit={setOpenEdit} isRetrying={true} updateFailed={setPaymentUpdateFailed} updateSuccessful={setPaymentUpdateSuccessful} returnUrl={`/settings?active=Billing&edit_payment_only=true`} />
            </Elements>
          </Modal>

          <Modal
            isOpen={openConfirmResubscribe}
            isLoading={isSaving}
            onClose={setOpenConfirmResubscribe}
            title="Reactivate Your Subscription"
            defaultCenterModal={true}
            onSubmit={async () => {
              try {
                await SubscriptionService.handleSubscriptionReactivation(organization?.id);
                enqueueSnackbar("Subscription Reactivated", { variant: "toast", width: "450px" });
              } catch (err: any) {
                enqueueSnackbar(err);
              }

              setOpenConfirmResubscribe(false);
            }}
            submitButtonText="Resubscribe">
            <div>
              <p>Would you like to resubscribe to <strong>Symmio Business Plan</strong>?</p>
              <br />
            </div>
          </Modal>
        </div >
      </div>
    </div >

  );
}
export default Billing;