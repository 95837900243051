import React, { useEffect, useState } from "react";
import { PaymentElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import OrganizationSubscriptionService from "../../utilities/services/organization-subscription-service";
import { WarningItem } from "../../components/warning-item/warning-item";
import Stripe from "stripe";
import { OrganizationSubscription } from "../../models/interfaces/organization-subscription";
import { PaymentCycle } from "../../models/enumerations/payment-cycle";
import { useHistory } from "react-router";
import BaseCardLayoutActions from "../../components/base-card-layout/base-card-layout-actions";
import UserService from "../../utilities/services/user-service";
import PriceCard from "./price-card";
import QrCodeCard from "./qr-code-card";
import { Utils } from "../../utilities/utils";
import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import FunctionService from "../../utilities/services/function-service";

interface SubscriptionLinkCheckoutFormProps {
  isLoading: boolean;
  setIsLoading: Function;
  showBackButton: boolean;
  redirectAfterPayment: boolean;
  showCongratsMessage?: boolean;
}

const COMPONENT_CLASS = "c-subscription-link-checkout";

const SubscriptionLinkCheckoutForm: React.FC<SubscriptionLinkCheckoutFormProps> = (props) => {
  const {
    isLoading,
    setIsLoading,
    showBackButton,
    redirectAfterPayment,
    showCongratsMessage,
  } = props;

  const { state, setState } = useAuthState();
  const stripe = useStripe();
  const stripe2: Stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState('');
  const [customer, setCustomer] = useState<Stripe.Customer>();
  const [paymentFailedBanner, setPaymentFailedBanner] = useState<boolean>(false);
  const [orgSub, setOrgSub] = useState<OrganizationSubscription>();
  const [selectedPlan, setSelectedPlan] = useState<PaymentCycle>();
  const [showCongratsScreen, setShowCongratsScreen] = useState(showCongratsMessage || false);
  const history = useHistory();

  if (elements) {
    const element = elements!.getElement('payment')
    if (element) {
      element.on('ready', () => {
        setIsLoading(false);
      })
    }
  }
  else {
    console.error("elements is null");
  }

  useEffect(() => {
    const getOrgSub = async () => {

      try {
        setIsLoading(true);

        const fetchedOrgSubArray = await OrganizationSubscriptionService.getByOrganizationId(state.organization.id!);

        if (fetchedOrgSubArray && fetchedOrgSubArray.length > 0) {
          setOrgSub(fetchedOrgSubArray[0]);
        }
      } catch (err) {
        console.error("Cannot fetch the organization subscription", err);
      } finally {
        setIsLoading(false);
      }
    }

    getOrgSub();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.organization.id])

  useEffect(() => {
    const confirmIntent = (async () => {
      setIsLoading(true);
      if (stripe && elements) {
        // Use the clientSecret and Elements instance to confirm the setup
        const { error, setupIntent } = await stripe.confirmSetup({
          elements,
          clientSecret,
          confirmParams: {
            return_url: window.location.origin,
          },
          redirect: "if_required"
        });
        if (error) {
          setIsLoading(false);
          setPaymentFailedBanner(true);
        }
        else if (customer) {
          // Update customer to use the new payment method as their default
          await stripe2.customers.update(
            customer.id,
            {
              invoice_settings: {
                default_payment_method: typeof setupIntent.payment_method === "string" ? setupIntent.payment_method : setupIntent.payment_method?.id,
              },
            }
          );

          await stripe2.subscriptions.create({
            customer: customer.id,
            items: [
              {
                price: selectedPlan === PaymentCycle.Monthly ? orgSub?.mySymmioMonthlyPriceId : orgSub?.mySymmioYearlyPriceId,
              }
            ],
            payment_behavior: 'error_if_incomplete',
            payment_settings: { save_default_payment_method: 'off' },
            expand: ['latest_invoice.payment_intent'],
          }).then(async (data) => {
            if (!state.user) {
              setIsLoading(false);
              return;
            }

            const user = state.user;

            let todayDate = new Date();
            const timeZoneOffset = todayDate.getTimezoneOffset();
            todayDate = new Date(todayDate.getTime() - (timeZoneOffset * 60 * 1000));
            const startDate = todayDate.toISOString().split('T')[0];

            user.subscription = {
              subscriptionId: data.id,
              startDate: startDate,
              didPaymentFail: false,
              wasSubscriptionCancelledManually: false,
              hasChargeError: false,
            };

            user.symmioAccess = SymmioAccessType.AppLicense;

            await UserService.save(state.user!);
            setState((state) => ({
              ...state, ...{
                user: { ...user }
              }
            }));

            if (redirectAfterPayment) {
              handleRedirectHome();
            } else {
              setShowCongratsScreen(true);
            }
            FunctionService.sendUserSubscriptionConfirmationEmail(state.user);
          }).catch((err: any) => {
            console.error(err);
            setPaymentFailedBanner(true);
          })
        }
      }
      setIsLoading(false);
    })
    if (clientSecret) {
      confirmIntent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSecret])

  const handleSubscriptionStart = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.error(submitError)
      return;
    }

    setIsLoading(true);

    // Retrieve customer from stripe by Email. Expand for Subscription
    let customers = await stripe2.customers.list({
      limit: 1,
      email: `${state.user?.email}`,
      expand: ['data.subscriptions']
    })

    let customer: Stripe.Customer;

    // Check if a customer was found
    if (customers.data.length === 0) {
      customer = await stripe2.customers.create({
        email: `${state.user?.email}`,
        name: `${state.user?.firstName} ${state.user?.lastName}`,
      })
    }
    else {
      customer = customers.data[0];
    }

    setCustomer(customer);

    await stripe2.setupIntents.create({
      customer: customer.id,
      // In the latest version of the API, specifying the `automatic_payment_methods` parameter is optional because Stripe enables its functionality by default.
      automatic_payment_methods: {
        enabled: true,
      },
    }).then((intent: any) => {
      setClientSecret(intent.client_secret);
    });

    setIsLoading(false);
  }

  const handleRedirectHome = () => {
    history.push("/wellness-results");
  }

  const handlePriceSelect = (paymentCycle: PaymentCycle) => {
    setSelectedPlan(paymentCycle);
  }

  return (
    <div className={`${COMPONENT_CLASS}`}>
      {showCongratsScreen
        ?
        <>
          <div className="flex flex-col gap-8 justify-center items-center mt-16 mb-8">
            <header>
              <h1 className="text-center">Congratulations on Your New Journey!</h1>
            </header>
            <main className="w-4/5 flex flex-col gap-8 justify-center">
              <div className={`${COMPONENT_CLASS}__congrats-text`}>
                You've unlocked access to your personalized Wellness Program – your personalized gateway to wellness. Discover your full potential while keeping focused on meeting your wellness goals in an easy-to-use app available on any mobile device.
              </div>
              <QrCodeCard email={state.user?.email} />
            </main>
          </div>
          <BaseCardLayoutActions backButton={false} submitButton={true} onSubmit={handleRedirectHome} submitButtonText={"Symmio Home"} submitDisabled={isLoading} />
        </>
        :
        <>
          <div className="flex flex-col gap-8 justify-center items-center mt-16 mb-8">
            <header className="flex flex-col justify-center items-center">
              {paymentFailedBanner && (
                <div className="w-4/5">
                  <WarningItem
                    iconColor="#E7514F"
                    onClose={() => { setPaymentFailedBanner(false); }}
                    backgroundColor="#FFE9E5"
                    title="Payment failed"
                    text="There was an error processing your payment. Make sure your payment information is entered correctly and try again, or try a different payment method."
                  />
                </div>
              )}

              <h1>Get Started with Symmio</h1>
            </header>
            <main className="lg:w-2/5 w-full flex flex-col gap-8 justify-center">
              <div className="flex flex-col gap-4">
                <h2 className="text-center">Select Your Subscription</h2>

                <PriceCard
                  isChecked={selectedPlan === PaymentCycle.Yearly}
                  topText="Annual"
                  price={(orgSub && orgSub.mySymmioYearlyPrice !== undefined) ? Number(orgSub.mySymmioYearlyPrice).toFixed(2) : "..."}
                  paymentCycle="year"
                  subText="Billed every year."
                  onClick={() => handlePriceSelect(PaymentCycle.Yearly)}
                  isHighlighted={selectedPlan === PaymentCycle.Yearly}
                  badgeText={Utils.getDiscountBadgeText(orgSub)}
                />
                <PriceCard
                  isChecked={selectedPlan === PaymentCycle.Monthly}
                  topText="Monthly"
                  price={(orgSub && orgSub.mySymmioMonthlyPrice !== undefined) ? Number(orgSub.mySymmioMonthlyPrice).toFixed(2) : "..."}
                  paymentCycle="month"
                  subText="Billed every month."
                  isHighlighted={selectedPlan === PaymentCycle.Monthly}
                  onClick={() => handlePriceSelect(PaymentCycle.Monthly)}
                />
              </div>

              <div className="flex flex-col gap-4">
                <h2>Enter Your Payment Details</h2>

                <div className={`${COMPONENT_CLASS}__checkout-form`}>
                  <div className={`${`${COMPONENT_CLASS}__form-container`}`}>
                    <form className={`${COMPONENT_CLASS}__form`}>
                      <PaymentElement />
                    </form>
                  </div>
                </div>
              </div>
            </main>
            <footer className={`${COMPONENT_CLASS}__bottom-text`}>
              By selecting ‘Start Subscription’, you agree that your subscription starts immediately and you allow Symmio to charge your card for this payment and future payments in accordance with Symmio’s terms and conditions. You can cancel your subscription at any time in your account settings, effective at the end of your billing cycle.
            </footer>
          </div>
          <BaseCardLayoutActions backButton={showBackButton} onBack={handleRedirectHome} submitButton={true} onSubmit={handleSubscriptionStart} submitButtonText={"Start Subscription"} submitDisabled={isLoading || selectedPlan === undefined || !stripe || !elements || !orgSub} />
        </>
      }
    </div>
  );
}

export default SubscriptionLinkCheckoutForm;