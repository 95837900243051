import React from "react";
import { Organization } from "../models/interfaces/organization";
import { PlanType } from "../models/enumerations/plan-type";
import { useAuthState } from "../utilities/contexts/auth-state-context";
import PortalProgressBar from "./portal-progress-bar/portal-progress-bar";
import { WarningItem } from "./warning-item/warning-item";
import { useHistory } from "react-router-dom";
import { CheckboxTypeInput } from "./forms/checkbox-type-input";
import { useForm } from "react-hook-form";

const COMPONENT_CLASS = "c-symmio-license-checkbox";

interface SymmioLicenseCheckboxProps {
    organization: Organization;
    onClick: () => void;
    isChecked: boolean;
    numberOfAddedLicenses: number;
    numberOfRemovedLicenses: number;
    isDisabled?: boolean;
}

export const SymmioLicenseCheckbox: React.FC<SymmioLicenseCheckboxProps> = (props) => {
    const {
        organization,
        onClick,
        isChecked,
        numberOfAddedLicenses,
        numberOfRemovedLicenses,
        isDisabled
    } = props;

    const { state } = useAuthState();
    const history = useHistory();

    const {
        register,
    } = useForm<any>();

    return (
        <div className={`${COMPONENT_CLASS}__checkbox-wrapper ${isChecked ? `${COMPONENT_CLASS}__is-symmio-access` : ``}`} onClick={isDisabled ? () => { } : onClick}>
            <div>
                <CheckboxTypeInput
                    id="symmioAccess"
                    inputClassName={`${COMPONENT_CLASS}__symmio-access-checkbox`}
                    registerHook={register}
                    registerOptions={{
                        onChange: onClick,
                        disabled: isDisabled,
                    }}

                    checked={isChecked} />

            </div>
            <div className={`${COMPONENT_CLASS}__checkbox-text`}>
                <strong className="mb-2 text-md">Enroll in Wellness Program</strong>
                <p className="text-sm font-light">Enroll users in a wellness program through the Symmio App. Once enrolled, they will receive personalized wellness programs, daily recommendations, and corrective exercises based on their assessment results, all conveniently accessible within the app.</p>
                <div className={`${COMPONENT_CLASS}__checkbox-info`}>
                    {
                        organization.plan === PlanType[PlanType.Enterprise] &&
                        isChecked && organization.mySymmioLicenseCount! + numberOfAddedLicenses > organization.mySymmioLicense! && (
                            <a href={`mailto:support@symmio.com?subject=URGENT: License Increase Request from ${organization.name}&body=We have received an urgent license increase request from one of our enterprise accounts. Please see the details below and follow up promptly.%0D%0AOrganization Name: ${organization.name}%0D%0ARequest Submitted By: ${state.user?.firstName} ${state.user?.lastName}%0D%0AAccount Owner Email: ${state.user?.email}%0D%0ACurrent License Count: ${organization.mySymmioLicenseCount}%0D%0ACurrent License Limit: ${organization.mySymmioLicense}`}
                                onClick={(e) => { e.stopPropagation() }}>Contact Us</a>
                        )
                    }
                    {
                        (organization.plan === PlanType[PlanType.Business] || organization.plan === PlanType[PlanType.Starter]) &&
                        isChecked && organization.mySymmioLicenseCount! + numberOfAddedLicenses > organization.mySymmioLicense! && (
                            <p style={{
                                color: "#121622",
                                fontSize: "14px",
                                fontWeight: "300",
                                textDecorationLine: "underline",
                                cursor: "pointer"
                            }} onClick={(event) => {
                                event.stopPropagation();
                                if (organization.plan! === PlanType[PlanType.Business]) {
                                    history.push("/settings?active=Add-Ons")
                                }
                                else {
                                    history.push(`/settings/upgrade`);
                                }
                            }}>Manage Add-ons</p>
                        )
                    }

                </div>
                <PortalProgressBar total={organization.mySymmioLicense!} currentProgress={isChecked ? organization.mySymmioLicenseCount! + numberOfAddedLicenses : organization.mySymmioLicenseCount! - numberOfRemovedLicenses} headerText={`${isChecked ? organization.mySymmioLicenseCount! + numberOfAddedLicenses : organization.mySymmioLicenseCount! - numberOfRemovedLicenses} of ${organization.mySymmioLicense!} Wellness Programs Used`}
                    link="/settings" linkText="Manage Add-Ons" />
                {
                    // if isChecked and license count > limit
                    isChecked && organization.mySymmioLicenseCount! + numberOfAddedLicenses > organization.mySymmioLicense! && (
                        <WarningItem
                            iconColor="#E7514F"
                            backgroundColor="#FFE9E5"
                            title="Not enough Wellness Programs available"
                            text="You don’t have enough Wellness Programs available for these users. Remove users from this invitation, or manage your add-ons to add additional Wellness Programs. "
                            marginBottom="0rem"
                        />
                    )
                }
            </div>
        </div>
    )
};
